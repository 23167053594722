import { Component, OnInit, TemplateRef, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { StoresService } from '../../services/stores.service';
import { Router } from '@angular/router';
import { ChatService } from '../../services/chat.service';
import { CmoapiService } from '../../services/cmoapi.service';
import { Channel } from 'twilio-chat/lib/channel';
import { HelperService } from '../../services/helper.service';
import * as Pubnub from 'pubnub';
import * as moment from 'moment';
import { environment } from '../../../environments/environment';
import { MessageService } from '../../services/message.service';
import { ConstantProviderService } from '../../providers/constant-provider.service';

@Component({
  selector: 'ngx-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, OnDestroy {

  //#region variables

  public storeId: any;
  public pubnub: any;
  public tempOrder: any;
  public storeDetails: any;
  public userRole: any;
  public pnChannelSub: any;
  public showUserDetails: any;
  public selectedStore: any;

  public messages: any[] = [];
  public stores: any[] = [];
  public duplicateStoresArray: any[] = [];
  public storeWiseChatUnreadMsgCountArray: any[] = [];
  public dialogRefs: any[] = [];
  
  public storeUID: string;
  public activeChannel: string = null;
  
  public users: {
    name: string,
    mobile: string,
    address: string,
    channel?: Channel,
    badge?: number,
    _id: string,
    customerDetail?: any,
    status: string
  }[] = [];

  public activeUser: {
    name: any,
    mobile: any,
    address?: any,
    channel?: Channel,
    badge?: number,
    _id: any,
    customerDetail?: any,
  } = {
    name: null,
    mobile: null,
    address: null,
    channel: null,
    _id: null,
  };

  public displayTypingIndicator: boolean;
  public isStoreDropDown: boolean;
  public isChatResponsive: boolean;

  //#endregion

  //#region life cycle hook

  constructor(
    private dialogService: NbDialogService,
    public storeService: StoresService,
    public router: Router,
    public toastrService: NbToastrService,
    public chatService: ChatService,
    public cdr: ChangeDetectorRef,
    public cmoApiService: CmoapiService,
    public helper: HelperService,
    public messageService: MessageService,
    public constant: ConstantProviderService,
  ) {
    this.storeId = this.helper.getLocalStore();

    setInterval(() => {
      this.updateStoreWiseUnreadMsgCount();
    }, constant.CHAT_COUNT_INTERVAL);

    if (window.innerWidth < 768) this.isChatResponsive = true;
    else this.isChatResponsive = false;
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    if (event.target.innerWidth) {
      const width = event.target.innerWidth
      if (width < 768) this.isChatResponsive = true;
      else this.isChatResponsive = false;
    }
  }

  ngOnInit(): void {
    const loggedInUserDetails = JSON.parse(localStorage.getItem('loggedInUserDetails'));

    if (loggedInUserDetails?.roles.includes('kitchenstaff') ||
        loggedInUserDetails?.roles.includes('storestaff')) this.userRole = true;
    else this.userRole = false;

    this.getStores();
    if (this.storeId) {
      this.getSelectedStoreDetails();
    }
  }

  ngOnDestroy() {
    this.chatService.CMO_CHAT_CLIENTS.forEach((ccl: any) => {
      ccl.client.removeAllListeners();
    });
    this.chatService.setActiveChannel(null);

    if (this.dialogRefs?.length > 0) {
      this.dialogRefs.forEach((list: any) => { list.close() });
    }
  }

  //#endregion

  //#region private methods

  private getSelectedStoreDetails() {
    if (this.storeId !== 'undefined' && this.storeId !== null) {
      this.cmoApiService.getSingleStoreDetails(this.storeId).then((res: any) => {
        this.storeDetails = res.data;
        this.storeUID = this.storeDetails.uid;
        this.pubnub = new Pubnub({
          publishKey: environment.pubnub.pk,
          subscribeKey: environment.pubnub.sk,
          uuid: this.storeDetails.uid,
        });
        this.pnChannelSub = this.storeUID + '.*';
        this.initPubnubChat();

      }, (error: any) => {
        const errMsg = this.helper.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastrService.danger(errMsg, this.constant.ERROR);
        }
      });

    } else {
      this.toastrService.warning(this.constant.SELECT_RESTAURANT_FIRST, this.constant.OOPS);
    }
  }

  private getTempOrder() {
    const getUserTempOrderObj = {
      store: this.storeId,
      customer: this.activeUser.customerDetail.customerid,
    };
    this.cmoApiService.getUserAllTempOrder(getUserTempOrderObj).then((res: any) => {
      this.tempOrder = res.data[0];

    }, (error: any) => {
      const errMsg = this.helper.getResponseErrorMessage(error);
      if (errMsg?.length) {
        this.toastrService.danger(errMsg, this.constant.ERROR);
      }
    });
  }

  private initPubnubChat() {
    this.pubnub.addListener({
      status: (st: any) => {
      },
      message: (msg: any) => {
        if (msg.actualChannel === this.activeChannel) {
          this.messages.push({
            text: msg.message,
            date: moment(msg.timetoken / 10000).toDate().getTime(),
            date_value: msg.timetoken,
            reply: (msg.publisher === this.storeUID),
            type: 'text',
            user: {
              name: msg.userMetadata['storeid'] ? this.storeDetails.name : msg.userMetadata.customer,
              avatar: 'https://i.gifer.com/no.gif',
            },
            read: false,
            channel: msg.channel,
          });
        }
        this.setMembershipIfNewMessage(msg.channel, msg.userMetadata);
      },
      presence: (event) => {
        if (this.users && this.users.length > 0) {
          this.users.forEach((user: any) => {
            if (user._id === event.channel && event.action === 'join') {
              user.status = 'online';
            }

            if (user._id === event.channel && event.action === 'leave') {
              user.status = 'offline';
            }
          });
        }
      },
      signal: (event) => {
        if (event.channel === this.activeChannel && event.message === 'typing_on') {
          this.displayTypingIndicator = true;
        }

        if (event.message === 'typing_off') {
          this.displayTypingIndicator = false;
        }
      },
      messageAction: (event) => {
        if (this.messages && this.messages.length > 0) {
          this.messages[this.messages.length - 1].read = event['data']['value'] === 'message_read';
        }
      },
      file: (event) => {
        if (event.channel === this.activeChannel) {
          const fileType = (event.file.name).substr(((event.file.name).lastIndexOf('.') + 1));
          this.messages.push({
            text: '',
            date: moment(event.timetoken / 10000).toDate().getTime(),
            date_value: event.timetoken,
            reply: (event.publisher === this.storeUID),
            type: 'file',
            files: [{
              url: 'http://' + event.file.url,
              icon: fileType === 'json' || fileType === 'pdf' || fileType === 'doc' ? 'file-text-outline' : '',
              type: fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ? 'image/jpeg' : fileType === 'gif'
                                  ? 'image/gif' : 'file',
            }],
            user: {
              name: this.storeDetails.name,
              avatar: 'https://i.gifer.com/no.gif',
            },
            read: false,
            channel: event.channel,
          });
        }
        this.setMembershipIfNewMessage(event.channel, null);
      },
      objects: (obj: any) => {

      },
    });
    this.subscribeChatChannel();
    this.getChannelMemberships();
  }

  private setMembershipIfNewMessage(channel: any, metaData: any) {
    this.pubnub.objects.setMemberships({
      channels: [channel],
      uuid: this.storeUID,
    }, (status, response) => {
      if (this.users && this.users.length > 0) {
        const existChannelIndex = this.users.findIndex((mbData: any) => mbData._id === channel);
        if (existChannelIndex !== -1) {
          if (this.users[existChannelIndex]._id !== this.activeChannel) {
            this.users[existChannelIndex].badge = this.users[existChannelIndex].badge + 1;
          }

        } else {
          this.users.unshift({
            _id: channel,
            name: channel,
            address: null,
            mobile: '0',
            badge: 1,
            customerDetail: metaData,
            status: 'online',
          });
        }

      } else {
        this.users.push({
          _id: channel,
          name: channel,
          address: null,
          mobile: '0',
          badge: 1,
          customerDetail: metaData,
          status: 'online',
        });
      }
    });
  }

  private subscribeChatChannel() {
    this.pubnub.subscribe({
      channels: [this.storeDetails.uid + '.*', '*.' + this.storeDetails.uid],
      withPresence: true,
    });

    this.pubnub.hereNow({
      includeUUIDs: true,
      includeState: true
    }, (status: any, response: any) => {
    });
  }

  private getFileUrl(channel: any, id: number, name: string): string {
    return this.pubnub['getFileUrl']({
      channel, id, name,
    });
  }

  private getChannelMetaData(mbData: any) {
    this.pubnub.objects.getChannelMetadata({
      channel: mbData.channel.id,

    }).then((response: any) => {
      this.users.push({
        _id: mbData.channel.id,
        name: mbData.channel.id,
        address: null,
        mobile: '0',
        badge: 0,
        customerDetail: response.data.custom,
        status: 'offline',
      });

      this.getChannelMessageCounts(mbData.channel.id);
      this.pubnub.hereNow({
        includeUUIDs: true,
        includeState: true,
      }, (status, resp) => {
        if (this.users && this.users.length > 0) {
          this.users.forEach((user: any) => {
            if (resp.channels[user._id]) {
              user.status = 'online';
            }
          });
        }
      });
    });
  }

  private getChannelMemberships() {
    this.pubnub.objects.getMemberships({ uuid: this.storeUID }).then((res: any) => {
      this.users = [];
      if (res?.data?.length > 0) {
        res.data.forEach((mbData: any) => {
          this.getChannelMetaData(mbData);
        });
      }

    }, (error: any) => {
      const errMsg = this.helper.getResponseErrorMessage(error);
      if (errMsg?.length) {
        this.toastrService.danger(errMsg, this.constant.ERROR);
      }
    });
  }

  private getChannelMessageCounts(channel: any) {
    this.pubnub.messageCounts({
      channels: [channel],
      channelTimetokens: [localStorage.getItem('messageReadTime')],
    }, (status, response) => {
      if (response && response.channels[channel]) {
        const existUserIndex = this.users && this.users.findIndex((user) => user._id === channel);
        if (existUserIndex !== -1) {
          this.users[existUserIndex].badge = response.channels[channel];
        }
      }
      this.getChannelMessageActions(channel);
    });
  }

  private getChannelMessageActions(channel: any) {
    this.pubnub.getMessageActions({
      channel: channel,
      end: `${(new Date()).getTime()}`,
    }, (status, response) => {
      if (response && response.data && response.data.length > 0) {
        response.data.forEach((messageDetail) => {});
      }
    });
  }

  private getStores() {
    const stores = this.helper.getAllStoresList();
    if (stores == undefined || stores == null || !stores.length) {
      this.cmoApiService.getAllStores().then((res: any) => {
        this.helper.setAllStoresList(res.data);
        this.updateSelectedStore(res.data);
  
      }, (error: any) => {
        const errMsg = this.helper.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastrService.danger(errMsg, this.constant.ERROR);
        }
      });

    } else {
      this.updateSelectedStore(stores);
    }
  }

  private sortArray(array) {
    return array.sort((a: any, b: any) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
  }

  private updateSelectedStore(data: any) {
    const result: any = data;
    this.stores = this.sortArray(result);
    this.updateStoreWiseUnreadMsgCount();
    const mapStoreArray: any[] = data.map((list: any) => { return list.name });
    this.duplicateStoresArray = mapStoreArray.filter((list: any, ind: number, array: any) => { return array.indexOf(list) != ind });

    const filterStore = this.stores.filter((list: any) => { return list._id == this.storeId});
    this.selectedStore = filterStore[0];
    this.cdr.detectChanges();
  }

  private async updateStoreWiseUnreadMsgCount() {
    if (this.stores?.length > 0) {
      this.storeWiseChatUnreadMsgCountArray = [];
      for (let store of this.stores) {
        const count = await this.chatService.getRestaurantChatCounts(store.uid);
        this.storeWiseChatUnreadMsgCountArray.push({ _id: store._id, count });
        this.cdr.detectChanges();
      }
    }
  }

  //#endregion

  //#region public methods

  public sendMessage(event: any) {
    const cm = event.message.trim();
    if ((!cm || cm.length === 0) && !event.files) {
      return;
    }

    if (this.activeChannel) {
      if (cm) {
        this.pubnub.publish({
          channel: this.activeChannel, message: cm, meta: { storeid: this.storeDetails._id },
        }, (status, response) => {
          this.pubnub.signal({
            message: 'portal_typing_off',
            channel: this.activeChannel,
          });
        });
      }

      if (event.files && event.files.length > 0) {
        event.files.forEach((file: any) => {
          this.pubnub['sendFile']({
            channel: this.activeChannel,
            file: file,
            meta: { storeid: this.storeDetails._id },
          }, (status, response) => {
          });
        });

      }
    } else {
      this.pubnub.publish({ 
          channel: '285eb2d8-9faa-46ff-bef6-db08fe5bca80.299d6d41-98d7-4322-9733-0e1f6014d0f1',
          message: cm }, (status, response) => {
      });
    }
  }

  public selectChannel(channel: any, chatRef: TemplateRef<any>) {
    if (this.isChatResponsive) {
      const ref2 = this.dialogService.open(chatRef);
      this.dialogRefs.push(ref2);
    }
    this.messageService.sendMessage({message: 'Select-User', data: channel.customerDetail});
    this.messageService.sendMessage({msg: 'Change-Customer-Id', customerId: channel.customerDetail.customerid });
    this.activeChannel = channel.name;
    channel.badge = 0;
    this.activeUser.customerDetail = channel.customerDetail;
    this.chatService.setActiveChannel(this.activeChannel);
    this.fetchOldChatMessages(this.activeChannel);
    this.getTempOrder();
  }

  public showUser(dialog: TemplateRef<any>, user: any) {
    this.showUserDetails = user;
    const ref1 = this.dialogService.open(dialog);
    this.dialogRefs.push(ref1);
  }

  public clearAllTempOrder() {
    if (this.activeUser?.customerDetail) {
      const getUserTempOrderObj = {
        store: this.storeId,
        customer: this.activeUser.customerDetail.customerid,
      };
      this.cmoApiService.clearAllTempOrder(getUserTempOrderObj).then((res: any) => {
        this.tempOrder = res.data;
        this.messageService.sendMessage({
          msg: 'Change-Customer-Id',
          customerId: this.activeUser.customerDetail.customerid
        });
        this.toastrService.success(res.message, this.constant.SUCCESS);

      }, (error: any) => {
        const errMsg = this.helper.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.toastrService.danger(errMsg, this.constant.ERROR);
        }
      });

    } else this.toastrService.danger(this.constant.SELECT_CUSTOMER_FIRST, this.constant.ERROR);
  }

  public fetchOldChatMessages(channel: any) {
    this.pubnub.fetchMessages({
      channels: [channel],
      includeMessageActions: true,

    }, (sts, resp) => {
      if (!sts.error && resp) {
        this.messages = [];

        if (resp.channels[channel]) {
          resp.channels[channel].forEach((msg: any) => {
            const splitChannel = msg.channel.split('.');
            const fileType = msg && msg.message.file ?
                            (msg.message.file.name).substr(((msg.message.file.name).lastIndexOf('.') + 1)) : '';

            this.messages.push({
              text: msg && msg.message.file ? '' : msg.message,
              date: moment(msg.timetoken / 10000).toDate(),
              date_value: msg.timetoken,
              reply: (msg.uuid === this.storeUID),
              type: msg && msg.message.file ? 'file' : 'text',
              files: msg && msg.message.file ?
                [{
                  url: 'http://' + this.getFileUrl(channel, msg.message.file.id, msg.message.file.name),
                  icon: fileType === 'json' || fileType === 'pdf' || fileType === 'doc' ? 'file-text-outline' : '',
                  type: fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' ? 'image/jpeg' :
                        fileType === 'gif' ? 'image/gif' : 'file',
                }]
                : [],
              user: {
                name: splitChannel[0] === msg.uuid ? this.storeDetails.name : this.activeUser.customerDetail.customer,
                avatar: this.activeUser?.customerDetail?.customerpic,
              },
              read: msg.data && msg.data.receipt && msg.data.receipt.message_read ? true : false,
              channel: msg.channel,
            });
            localStorage.setItem('messageReadTime', msg.timetoken);
          });
        }
      }
    });
  }

  public onTypingMessage(event: any) {
    if (event.target.value) {
      this.pubnub.signal({
        message: 'portal_typing_on',
        channel: this.activeChannel,
      });

    } else {
      this.pubnub.signal({
        message: 'portal_typing_off',
        channel: this.activeChannel,
      });
    }
  }

  public loadOrderInPos() {
    this.messageService.sendMessage({message: 'Load-Order', data: this.tempOrder});
    this.toastrService.success('Order Loaded in POS Successfully', 'success');
  }

  public getConvertedName(name: string) {
    if (name) {
      const split = name.split(' ');
      return `${split[0]} ${split[1] ? (split[1].substr(0, 1) + '.') : '' }`
    }
  }

  public deleteChatMessage(msg: any) {
    const stamp = (Number(msg.date_value.substring(14, 17)) - 1).toString();
    this.pubnub.deleteMessages({
      channel: msg.channel,
      start: `${msg.date_value.substring(0, 14)}${stamp}`, 
      end: msg.date_value.toString(),
    });
    setTimeout(() => {
      this.toastrService.success(this.constant.CHAT_MESSAGE_DELETED, this.constant.SUCCESS);
      this.fetchOldChatMessages(this.activeChannel);
    }, 2000)
  }

  public getChatTimeStampDate(deleteMsgDialog: TemplateRef<any>, msg: any) {
    if (msg?.files[0]?.type?.includes('image/') && msg?.reply == true) {
      const dialog = this.dialogService.open(deleteMsgDialog);
      this.dialogRefs.push(dialog);

      dialog.onClose.subscribe((result: any) => {
        if (result == 'yes') {
          this.deleteChatMessage(msg);
        }
      });
    }
  }

  public async storeChange(value: any, url?: string) {
    const filterStore = this.stores.filter((list: any) => { return list._id == value});
    this.helper.setStore(filterStore[0]);
    window.location.reload();
    // this.helper.refreshComponent(url ? url : window.location.pathname);
    this.cdr.detectChanges();
  }

  public checkDuplicateStore(name: string): boolean {
    if (this.duplicateStoresArray?.length) {
      if (this.duplicateStoresArray.includes(name)) return true;
      else return false;
    }
  }

  public getChatCount(id: string) {
    if (this.storeWiseChatUnreadMsgCountArray?.length > 0) {
      const ind = this.storeWiseChatUnreadMsgCountArray.findIndex((list: any) => { return list._id == id });
      if (ind > -1) return this.storeWiseChatUnreadMsgCountArray[ind].count;
    }
  }

  //#endregion
}
